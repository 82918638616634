.card1 {
  position: relative;
  background-color: white;
  padding: 67px;
  border: 2px solid #0978ab;
  border-radius: 25px;
  margin-bottom: 20px;
  text-align: center;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease;
  margin: 30px;
  box-shadow: 0 4px 8px rgba(24, 130, 176, 0.5);
  cursor: pointer; /* Pointer cursor on hover */
}

.card1::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #0978ab, #45b6e4);
  transform: translateX(-100%);
  transition: transform 0.6s ease;
  z-index: 0;
}

.card1:hover::before {
  transform: translateX(0); /* Fill from left to right on hover */
}

.card1:hover {
  transform: translateY(-8px) scale(1.05); /* Lift and enlarge card */
  box-shadow: 0 12px 24px rgba(9, 120, 171, 0.7); /* Enhanced shadow */
  border-color: white; /* Change border color */
}

.card1 h3 {
  position: relative;
  margin: 0;
  font-size: 1.4em;
  color: #0978ab;
  z-index: 1;
  transition: transform 0.3s ease, color 0.3s ease;
}

.card1:hover h3 {
  color: white; /* Change text color */
  transform: scale(1.2); /* Slightly enlarge text */
}
