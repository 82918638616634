html, body {
  background-color: white;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.userpage4 {
  background-color: white;
  min-height: 100vh;
  padding: 20px; 
}

.card-container4 {
  max-width: 1550px;
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Keeps the grid layout the same */
  gap: 20px; /* Optional: adjust the gap between cards */
}

.card4 {
  position: relative; 
  background-color: white;
  padding: 70px;
  border: 2px solid #0978ab; 
  border-radius: 20px;
  margin-bottom: 20px;
  text-align: center;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease, border-color 0.6s ease;
  margin: 30px auto; /* Centers the cards within their container */
  box-shadow: 0 4px 8px rgba(24, 130, 176, 0.5);
  max-width: 300px; /* Decreases the card width */
}

.card4::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0978ab; 
  transform: translateX(-100%); 
  transition: transform 0.6s ease; 
  z-index: 0; 
}

.card4:hover::before {
  transform: translateX(0);
}

.card4:hover {
  border-color: white;
  transform: translateY(-8px) scale(1.05); 
  box-shadow: 0 12px 24px rgba(9, 120, 171, 0.7);
}

.card4:hover h3 {
  color: white; 
}

.card4 h3 {
  position: relative; 
  margin: 0;
  font-size: 1.2em;
  color: #0978ab; 
  z-index: 1; 
}

.card-link4 {
  text-decoration: none;
  color: inherit;
}

@media (max-width: 767px) {
  .card-container4 {
    grid-template-columns: 1fr;
    padding: 0 20px; 
  }

  .card4 {
    margin: 10px 0; 
    padding: 20px;
    max-width: 100%; /* Allows the card to take full width on smaller screens */
  }
}
