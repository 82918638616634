/* src/styles/LoginPage.css */

.login-page {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%; /* Adjusted width */
  max-width: 500px; /* Increased max-width */
  padding: 40px; /* Increased padding */
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.login-page h2 {
  margin-bottom: 20px;
  text-align: center;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}

.form-group input[type="email"],
.form-group input[type="password"] {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-group input[type="email"]:focus,
.form-group input[type="password"]:focus {
  outline: none;
  border-color: #007bff;
}

.error {
  color: #dc3545;
  font-size: 14px;
  margin-top: 10px;
}

button[type="submit"] {
  width: 100%;
  padding: 12px;
  background-color: #0978ab;
  color: #fff;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
}

/* button[type="submit"]:hover {
  background-color: #0056b3;
} */
