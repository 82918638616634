/* CustomerList.css */

/* Container styles */
.customer-list-container {
  padding: 20px;
  background-color: #f9f9f9;
}

.customer-list-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.search-input06 {
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 25px;
  font-size: 16px;
  width: 200px;
}

.add-customer-button {
  padding: 15px;
  background-color: #0978ab;
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
}

.add-fetch-button {
  padding: 12px;
  background-color: #0978ab;
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
  margin-left:50px;
}

/* Table styles */
.table-responsive {
  overflow-x: auto;
}

.customer-list-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  border-radius: 25px;
  overflow: hidden;
}

.customer-list-table thead {
  background-color: #0978ab;
  color: white;
}

.customer-list-table th, .customer-list-table td {
  border: 1px solid #ddd;
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}




/* Action buttons styles */
.action-buttons-container {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.edit-button19, .delete-button19 {
  padding: 15px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 14px;
  color: white;
}

.edit-button19 {
  background-color: #0978ab;
}

.delete-button19 {
  background: #dc3545;
}
/* Popup Container */
.customer-popup {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.5);
display: flex;
justify-content: center;
align-items: center;
z-index: 1000;
}

/* Popup Content */
.customer-popup-content {
background-color: #fff;
padding: 20px;
border-radius: 8px;
box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
width: 400px;
max-width: 90%;
display: flex;
flex-direction: column;
position: relative; /* Ensure that the children are positioned relative to this */

}

/* Popup Header */
.customer-popup-content h2 {
margin-top: 0;
font-size: 24px;
text-align: center;
}

/* Form Group */
.form-group-cust {
margin-bottom: 15px;
}

.form-group-cust label {
display: block;
margin-bottom: 5px;
font-weight: 600;
}

.form-group-cust input {
  border: none;
  border-bottom: 2px solid #000; /* Change the color and width as needed */
  outline: none;
  width: 100%; /* Adjust the width as necessary */
  padding: 5px 0;
  font-size: 16px; /* Adjust the font size as needed */
  background-color: transparent; /* Make sure the background is transparent */
}

.form-group-cust input:focus {
  border-bottom-color: #007bff; /* Change the color on focus if needed */
}


.landscape-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.map-container {
  flex: 2;
  min-width: 400px;
}

.location-details {
  margin-top: 20px;
}  


/* Header for the form */
.customer-popup h2 {
  margin-bottom: 20px;
  text-align: center;
  font-size: 24px;
  color: #333;
}

/* Form group for each input field */
.form-group-cust {
  flex: 1;
  min-width: 200px;
}


/* Labels for the input fields */
.form-group-cust label {
  margin-bottom: 5px;
  font-weight: bold;
  color: #555;
}

/* Input fields and textareas */
.form-group-cust input,
.form-group-cust textarea,
.form-group-cust select {
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s ease;
}

/* Date input field */
.form-group-cust input[type="date"] {
  padding: 6px;
}

/* Hover effect for inputs */
.form-group-cust input:focus,
.form-group-cust textarea:focus,
.form-group-cust select:focus {
  border-color: #007BFF;
  outline: none;
}


/* Style for Save button */
.save-button32 {
  background-color: #0978ab;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  flex: 1;
}
  
/* Style for Cancel button */
.cancel-button35 {
  background-color: #dc3545;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  flex: 1;
}


/* Style for Close button */
.close-button32 {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #dc3545;
  color: white;
  border: none;
  padding: 15px; /* Adjust padding to ensure square shape */
  border-radius: 25px; /* Slightly rounded corners */
  font-size: 16px; /* Font size for better visibility */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center; /* Center text inside the button */
}



.add-address-button{
  background-color: #0978ab;
  color: #fff;
  padding: 15px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease;

}
/* Modal styles */
.modal-overlayb {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-contentb {
  background-color: #fff;
  padding: 20px;
  border-radius: 25px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}



.Confirm, .Cancel {
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
}

.Confirm {
  background-color: #0978ab;
  color: white;
}

.Cancel {
  background: #dc3545;
  color: white;
}

.input-group1 textarea {
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s ease;
  width: 200px;
}

.input-group1 input[type="textarea"] {
  padding: 20px;
  width: 400px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .customer-list-container {
    padding: 15px;
  }

  .customer-list-actions {
    flex-direction: column;
    align-items: flex-start;
  }

  .search-input06 {
    width: 93%;
    margin-bottom: 10px;
    padding: 10px;
  }

  .add-customer-button {
    width: 100%;
    text-align: center;
    font-size: 15px;
    padding: 10px;
  }

  .customer-list-table th, .customer-list-table td {
    padding: 10px;
    font-size: 14px;
  }

  .edit-button19, .delete-button19 {
    padding: 10px;
    font-size: 12px;
  }

  .customer-popup-content {
    max-width: 90%;
  }

  .modal-contentb {
    max-width: 90%;
  }
  .customer-popup-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 25px;
    max-width: 350px;
    width: 100%;
    font-size: 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  /* .save-button32,.cancel-button32,.close-button32{
    padding: 10px;
    font-size: 15px;
  } */
}

@media (max-width: 480px) {
  .customer-list-container {
    padding: 10px;
  }

  .search-input06 {
    width: 91%;
    margin-bottom: 10px;
    font-size: 10px;
  }

  .add-customer-button {
    font-size: 14px;
    padding: 10px;
  }

  .customer-list-table th, .customer-list-table td {
    padding: 8px;
    font-size: 14px;
  }

  .edit-button19, .delete-button19 {
    padding: 8px;
    font-size: 12px;
  }

  /* .save-button32,.cancel-button32,.close-button32{
    padding: 8px;
    font-size: 12px;
  } */

  .customer-popup-content, .modal-contentb {
    padding: 15px;
  }
  .customer-popup-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 25px;
    max-width: 350px;
    width: 100%;
    font-size: 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
}

/* Popup Container */
.customer-popup1 {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.5);
display: flex;
justify-content: center;
align-items: center;
z-index: 1000;
}

/* Popup Content */
.customer-popup-content1 {
background-color: #fff;
padding: 20px;
border-radius: 8px;
box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
width: 400px;
max-width: 90%;
overflow-y: auto;
max-height: 80vh;
}

/* Popup Header */
.customer-popup-content1 h2 {
margin-top: 0;
font-size: 24px;
text-align: center;
}

/* Form Group */
.form-group-cust1 {
margin-bottom: 15px;
}

.form-group-cust1 label {
display: block;
margin-bottom: 5px;
font-weight: 600;
}

.form-group-cust1 input[type="text"],
.form-group-cust1 input[type="tel"] {
width: 100%;
padding: 10px;
border-radius: 4px;
border: 1px solid #ccc;
font-size: 16px;
box-sizing: border-box;
margin-bottom: 15px;
}


.button-container {
  position: relative;
  /* Adjust width and padding as needed */
  width: 100%; 
  padding: 10px;
}

.close-button {
  position: absolute;
  top: -740px;
  right: 10px;
  background-color:#dc3545;
  border: none;
  border-radius: 25px;
  padding: 15px;
  cursor: pointer;
  /* Add additional styling as needed */
}

.save-button {
  background-color: #0978ab; /* Green */
  border: none;
  color: white;
  padding: 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 25px;
}

.pagination1 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

/* Pagination buttons (page numbers) */
.pagination1 .MuiPaginationItem-page,
.pagination1 .MuiPaginationItem-previous,
.pagination1 .MuiPaginationItem-next {
  padding: 10px 20px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 25px;
  font-size: 16px;
  border: 2px solid #0978ab;
  /* Blue border for all pagination items */
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

/* Inactive page numbers (transparent background) */
.pagination1 .MuiPaginationItem-page:not(.Mui-selected) {
  background-color: transparent;
  color: #0978ab;
  /* Blue text color */
}

/* Hover effect for inactive page numbers */
.pagination1 .MuiPaginationItem-page:not(.Mui-selected):hover {
  background-color: #0978ab;
  /* Blue background on hover */
  color: white;
  /* White text on hover */
}

/* Highlight only the selected page button */
.pagination1 .MuiPaginationItem-page.Mui-selected {
  background-color: #0978ab !important;
  color: white !important;
  font-weight: bold;
  border: 2px solid #0978ab;
  /* Blue border for the selected page number */
}

/* Previous and Next button styling */
.pagination1 .MuiPaginationItem-previous,
.pagination1 .MuiPaginationItem-next {
  background-color: #0978ab;
  color: white;
}

/* Keep Previous and Next buttons blue on hover */
.pagination1 .MuiPaginationItem-previous:hover,
.pagination1 .MuiPaginationItem-next:hover {
  background-color: #4e8eac;
  color: white;
}

/* Disabled state for Previous and Next buttons */
.pagination1 .MuiPaginationItem-previous.Mui-disabled,
.pagination1 .MuiPaginationItem-next.Mui-disabled {
  cursor: not-allowed;
  background-color: #0978ab;
  color: #bdbdbd;
  border: 2px solid #0978ab;
  /* Blue border for disabled buttons */
}


.filter-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

.filter-button {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

/* .hide-filter-button {
} */

.filter-options {
  margin-left: auto; /* Keep this to push filter options to the right */
  /* Remove background color, padding, and box-shadow */
}



/* Responsive Styling */
@media (max-width: 480px) {
.customer-popup-content1 {
  width: 100%;
  padding: 15px;
}

.button-container32 {
  flex-direction: column;
  gap: 10px;
}

/* .save-button32,
.cancel-button32,
.close-button32 {
  width: 100%;
} */
}


/* Popup Container */
.customer-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Popup Content */
.customer-popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  width: 600px;
  max-width: 90%;
  overflow-y: auto;
  max-height: 85vh;
}

/* Popup Header */
.customer-popup-content h2 {
  margin-top: 0;
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
}

/* Form Row */
.form-row {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.form-container {
  display: grid;
  grid-template-columns: 150px 1fr; /* Fixed width for labels, flexible width for inputs */
  gap: 10px 20px; /* Space between rows and columns */
  align-items: center; /* Center items vertically */
  margin-bottom: 20px; /* Space below the form */
}

.form-container label {
  font-size: 16px; /* Consistent font size */
  white-space: nowrap; /* Prevent label text from wrapping */
}

.form-container input,
.form-container select {
  border: none;
  border-bottom: 1px solid #000; /* Underline style for inputs */
  outline: none;
  padding: 5px 0; /* Vertical padding */
  font-size: 16px; /* Consistent font size */
  background-color: transparent; /* Transparent background */
  width: 100%; /* Full width of the grid column */
}

.form-container input:focus,
.form-container select:focus {
  border-bottom-color: #007bff; /* Change underline color on focus */
}


/* Map Container */
.map-container {
  flex: 1 1 100%;
  height: 200px;
  margin-bottom: 20px;
}

.location-details {
  flex: 1 1 100%;
  margin-bottom: 20px;
}

.location-details p {
  margin: 5px 0;
  font-size: 14px;
  color: #555;
}

.location-details p strong {
  color: #333;
}


/* Popup Container */
.create-order-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Popup Content */
.create-order-popup-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  max-height: 80vh; /* Max height to allow scrolling */
  overflow-y: auto; /* Enable vertical scrolling */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}

/* Form Container */
.form-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Labels */
.form-container label {
  margin-bottom: 5px;
  /* font-weight: bold; */
  align-self: flex-start; /* Align labels to the left */
}

/* Input Fields */
.form-container input,
.form-container select {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  width: 100%;
}

/* Google Map Container */
.google-map-container {
  height: 300px; /* Adjust height as needed */
  width: 100%;
  margin: 15px 0;
  position: relative;
}

/* Google Map Search Bar */
.google-map-container input {
  position: absolute;
  top: 10px;
  left: 10px;
  width: calc(100% - 20px); /* Ensure the input is within the map container */
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: white; /* White background for better visibility */
  z-index: 1000; /* Ensure the input is above other elements */
}

/* Buttons */
.button-container32 {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

/* .cancel-button32,
.save-button32,
.close-button32 {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
} */

/* .cancel-button32 {
  background: #f44336; 
  color: white;
   border-radius: 25px;
  max-width: 150px; 
  padding: 10px;
} */

.save-button32 {
  background: #4caf50; /* Green for save */
  color: white;
  max-width: 150px; 
}

.close-button32 {
  background:  #f44336; /* Grey for close */
  color: white;
}
.order{
  margin-top: 80px;
  width: 500px;
  font-family: bold;
}

.input-group1 {
  display: flex;
  align-items: center; /* Center items vertically */
  margin-bottom: 20px; /* Space between lines */
}

.input-group1 label {
  margin-right: 10px; /* Space between label and input */
  /* font-weight: bold; */
  font-size: 16px;
  font-family: sans-serif;
}

.line-input1 {
  border: none;
  border-bottom: 1px solid #000; /* Adjust the color and width as needed */
  outline: none;
  padding: 5px 0;
  font-size: 1em;
  background: transparent;
  width: 250px; /* Adjust as needed */
}


.order-input-group {
display: flex;
align-items: center;
margin-top: 14px;
margin-bottom: 40px;
font-size: bold;

}

.order-input {
margin-right: 5px;
margin-top: 30px;
}

.button-container32 {
  display: flex;
  justify-content: space-between; 
  align-items: center; 
  gap: 10px; 
}

.close-button32 {
  background: #dc3545;
  color: white;
  border: none;
  padding: 15px;
  border-radius: 25px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  align-self: flex-end;  
  margin-bottom: 20px;    
}


.save-button32 {
  background-color: #0978ab; 
  color: white;
  border: none;
  padding: 15px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 25px;
  overflow: hidden;
  /* flex: 1; */
  margin-top: 20px;
  width: 130px;
}

.cancel-button32 {
  background: #dc3545;
  color: white;
  border: none;
  font-size: 16px;
  cursor: pointer;
  border-radius: 25px;
  margin-top: 20px;
  max-width: 150px; 
  padding: 10px;
}



.filter-container1 {
  display: flex;
  flex-direction: column;
  gap: 1px; /* Space between lines */
}

.filter-line1 {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between items in a line */
  height: 50px; /* Fixed height for the first line to avoid resizing */
}

.dynamic-content-wrapper {
  display: flex;
  gap: 20px; /* Space between search bar and calendar */
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
  align-items: center; /* Vertically align items */
}

.search-bar-wrapper1 {
  width: 200px; /* Fixed width for the search bar */
  display: flex; /* Ensure it stays within the same row */
}

.calendar-section1 {
  display: flex;
  gap: 15px; /* Space between date fields */
  align-items: center; /* Align date fields vertically */
}

.calendar-section1 > div {
  display: flex;
  flex-direction: row; /* Change direction to row so Start and End Date stay on the same line */
  gap: 15px; /* Space between Start and End Date */
}

.filter-line1 label {
  margin-right: 10px; /* Space between radio buttons */
}

