/* CustomerList.css */

/* Container styles */
.customer-list-container {
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  .customer-list-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  /* Styling for the heading */
.customer-list-actions h1 {
  margin-bottom: 20px; /* Add spacing below the heading */
  margin-left: -300px;
}

  .search-input06 {
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 25px;
    font-size: 16px;
    width: 200px;
  }
  
  .add-customer-button {
    padding: 15px;
    background-color: #0978ab;
    color: white;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    font-size: 16px;
  }
  
  /* Table styles */
  .table-responsive {
    overflow-x: auto;
  }
  
  .customer-list-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
    border-radius: 25px;
    overflow: hidden;
  }
  
  .customer-list-table thead {
    background-color: #0978ab;
  }
  
  .customer-list-table th, .customer-list-table td {
    border: 1px solid #ddd;
    padding: 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  
  
  /* Action buttons styles */
  .action-buttons-container {
    display: flex;
    gap: 10px;
    justify-content: center;
  }
  
  .edit-button19, .delete-button19 {
    padding: 15px;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    font-size: 14px;
    color: white;
  }
  
  .edit-button19 {
    background-color: #0978ab;
  }
  
  .delete-button19 {
    background: #dc3545;
  }
  /* Popup Container */
.customer-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Popup Content */
.customer-popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  width: 400px;
  max-width: 90%;
}

/* Popup Header */
.customer-popup-content h2 {
  margin-top: 0;
  font-size: 24px;
  text-align: center;
}

/* Form Group */
.form-group-cust {
  margin-bottom: 15px;
}

.form-group-cust label {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
}

.form-group-cust input {
  border: none;
  border-bottom: 2px solid #000; /* Change the color and width as needed */
  outline: none;
  width: 100%; /* Adjust the width as necessary */
  padding: 5px 0;
  font-size: 16px; /* Adjust the font size as needed */
  background-color: transparent; /* Make sure the background is transparent */
}



  .landscape-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .form-row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  .map-container {
    flex: 2;
    min-width: 400px;
  }
  
  .location-details {
    margin-top: 20px;
  }  
  
  
  /* Header for the form */
  .customer-popup h2 {
    margin-bottom: 20px;
    text-align: center;
    font-size: 24px;
    color: #333;
  }
  
  /* Form group for each input field */
  .form-group-cust {
    flex: 1;
    min-width: 200px;
  }
  
  
  /* Labels for the input fields */
  .form-group-cust label {
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
  }
  
  /* Input fields and textareas */
  .form-group-cust input,
  .form-group-cust textarea,
  .form-group-cust select {
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s ease;
  }
  
  /* Date input field */
  .form-group-cust input[type="date"] {
    padding: 6px;
  }
  
  /* Hover effect for inputs */
  .form-group-cust input:focus,
  .form-group-cust textarea:focus,
  .form-group-cust select:focus {
    border-color: #007BFF;
    outline: none;
  }
  
 /* Container for buttons */
.button-container10 {
  display: flex; /* Align buttons in a row */
  justify-content: space-between; /* Distribute space between buttons */
  align-items: center; /* Center buttons vertically */
  gap: 10px; /* Space between buttons */
}
.close-button10 {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #dc3545;
  color: white;
  border: none;
  padding: 15px; /* Adjust padding to ensure square shape */
  border-radius: 25px; /* Slightly rounded corners */
  font-size: 16px; /* Font size for better visibility */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center; /* Center text inside the button */
}
/* Styles for Save Changes button */
.save-button10 {
  background-color: #0978ab; /* Blue background */
  color: white;
  border: none;
  padding: 15px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 25px;
  overflow: hidden;
  flex: 1;
  margin-top: 20px;
}

  
 
  
  
 
  .add-address-button{
    background-color: #0978ab;
    color: #fff;
    padding: 15px;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;

  }
  /* Modal styles */
  .modal-overlayb {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-contentb {
    background-color: #fff;
    padding: 20px;
    border-radius: 25px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
  }
  
  .button-container32 {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .Confirm, .Cancel {
    padding: 10px 20px;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .Confirm {
    background-color: #0978ab;
    color: white;
  }
  
  .Cancel {
    background: #dc3545;
    color: white;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .customer-list-container {
      padding: 15px;
    }
  
    .customer-list-actions {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .search-input06 {
      width: 93%;
      margin-bottom: 10px;
      padding: 10px;
    }
  
    .add-customer-button {
      width: 100%;
      text-align: center;
      font-size: 15px;
      padding: 10px;
    }
  
    .customer-list-table th, .customer-list-table td {
      padding: 10px;
      font-size: 14px;
    }
  
    .edit-button19, .delete-button19 {
      padding: 10px;
      font-size: 12px;
    }
  
    .customer-popup-content {
      max-width: 90%;
    }
  
    .modal-contentb {
      max-width: 90%;
    }
    .customer-popup-content {
      background-color: #fff;
      padding: 20px;
      border-radius: 25px;
      max-width: 350px;
      width: 100%;
      font-size: 12px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
    .save-button32,.cancel-button32,.close-button32{
      padding: 10px;
      font-size: 15px;
    }
  }
  
  @media (max-width: 480px) {
    .customer-list-container {
      padding: 10px;
    }
  
    .search-input06 {
      width: 91%;
      margin-bottom: 10px;
      font-size: 10px;
    }
  
    .add-customer-button {
      font-size: 14px;
      padding: 10px;
    }
  
    .customer-list-table th, .customer-list-table td {
      padding: 8px;
      font-size: 14px;
    }
  
    .edit-button19, .delete-button19 {
      padding: 8px;
      font-size: 12px;
    }
  
    .save-button32,.cancel-button32,.close-button32{
      padding: 8px;
      font-size: 12px;
    }
  
    .customer-popup-content, .modal-contentb {
      padding: 15px;
    }
    .customer-popup-content {
      background-color: #fff;
      padding: 20px;
      border-radius: 25px;
      max-width: 350px;
      width: 100%;
      font-size: 12px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
  }
  
  /* Popup Container */
.customer-popup1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Popup Content */
.customer-popup-content1 {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  width: 400px;
  max-width: 90%;
  overflow-y: auto;
  max-height: 80vh;
}

/* Popup Header */
.customer-popup-content1 h2 {
  margin-top: 0;
  font-size: 24px;
  text-align: center;
}

/* Form Group */
.form-group-cust1 {
  margin-bottom: 15px;
}

.form-group-cust1 label {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
}

.form-group-cust1 input[type="text"],
.form-group-cust1 input[type="tel"] {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
  box-sizing: border-box;
  margin-bottom: 15px;
}

/* Button Container */
.button-container32 {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

/* Styles for Save Changes button */
.save-button10 {
  background-color: #0978ab; /* Blue background */
  color: white;
  border: none;
  padding: 15px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 25px;
  overflow: hidden;
  flex: 1;
  margin-top: 20px;
}

/* Styles for Cancel button */
.cancel-button10 {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 15px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 25px;
  margin-top: 20px;
}

.cancel-button32 {
  background-color: #f44336;
  color: white;
}

.close-button32 {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #dc3545;
  color: white;
  border: none;
  padding: 15px; /* Adjust padding to ensure square shape */
  border-radius: 25px; /* Slightly rounded corners */
  font-size: 16px; /* Font size for better visibility */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center; /* Center text inside the button */
}



/* Responsive Styling */
@media (max-width: 480px) {
  .customer-popup-content1 {
    width: 100%;
    padding: 15px;
  }

  .button-container32 {
    flex-direction: column;
    gap: 10px;
  }

  .save-button32,
  .cancel-button32,
  .close-button32 {
    width: 100%;
  }
}


.pagination1 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

/* Pagination buttons (page numbers) */
.pagination1 .MuiPaginationItem-page,
.pagination1 .MuiPaginationItem-previous,
.pagination1 .MuiPaginationItem-next {
  padding: 10px 20px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 25px;
  font-size: 16px;
  border: 2px solid #0978ab;
  /* Blue border for all pagination items */
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

/* Inactive page numbers (transparent background) */
.pagination1 .MuiPaginationItem-page:not(.Mui-selected) {
  background-color: transparent;
  color: #0978ab;
  /* Blue text color */
}

/* Hover effect for inactive page numbers */
.pagination1 .MuiPaginationItem-page:not(.Mui-selected):hover {
  background-color: #0978ab;
  /* Blue background on hover */
  color: white;
  /* White text on hover */
}

/* Highlight only the selected page button */
.pagination1 .MuiPaginationItem-page.Mui-selected {
  background-color: #0978ab !important;
  color: white !important;
  font-weight: bold;
  border: 2px solid #0978ab;
  /* Blue border for the selected page number */
}

/* Previous and Next button styling */
.pagination1 .MuiPaginationItem-previous,
.pagination1 .MuiPaginationItem-next {
  background-color: #0978ab;
  color: white;
}

/* Keep Previous and Next buttons blue on hover */
.pagination1 .MuiPaginationItem-previous:hover,
.pagination1 .MuiPaginationItem-next:hover {
  background-color: #4e8eac;
  color: white;
}

/* Disabled state for Previous and Next buttons */
.pagination1 .MuiPaginationItem-previous.Mui-disabled,
.pagination1 .MuiPaginationItem-next.Mui-disabled {
  cursor: not-allowed;
  background-color: #0978ab;
  color: #bdbdbd;
  border: 2px solid #0978ab;
  /* Blue border for disabled buttons */
}

.heading{
margin-right: -80px;}