.profile-drawer {
  position: fixed;
  top: 0;
  right: -100%; /* Initially hidden */
  height: 100%;
  width: 360px;
  background-color: white;
  transition: transform 0.3s ease, box-shadow 0.3s ease, border-color 0.6s ease; 
  box-shadow: 0 4px 8px rgba(24, 130, 176, 0.5);
  z-index: 1000;
  border-color: #0978ab;
  border-radius: 25px 0px 0px 25px;
  overflow: hidden;
}

.profile-drawer.open {
  right: 0; 
}

.profile-container {
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 50px;
}

.profile-heading {
  margin-bottom: 20px;
  font-size: 24px;
  text-align: center;
}

.user-info {
  display: flex;
  flex-direction: column; 
  gap: 15px; 
  
}

.user-info .info-item {
  display: flex;
  align-items: center; 
  font-size: 16px; 
  margin-left: 80px;
}

.user-info .info-item strong {
  margin-right: 10px; 
  font-weight: bold; 
}


.input-group {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.input-group label {
  flex: 0 0 150px;
  font-weight: bold;
}

.profile-input {
  flex: 1;
  border: 1px solid #ccc;
  border-radius: 25px;
  padding: 10px;
}


.btn-group {
  display: flex;
  gap: 10px; 
  margin-bottom: 20px; 
  justify-content: center;

}

.btn {
  padding: 10px 20px; 
  border: none;
  border-radius: 5px;
  cursor: pointer;
  align-items: center;
}

.edit-btn {
  background-color: #0978ab;
  color: white;
  padding: 15px;
  border-radius: 25px;
}

.save-btn {
  background-color: #0978ab;
  color: white;
  padding: 15px;
  border-radius: 25px;
}

.logout-btn {
  background-color: #dc3545;
  color: white;
  padding: 15px;
  border-radius: 25px;
}

.info-item {
  margin-bottom: 10px;
}

.btn-group.logout-group {
  display: flex;
  justify-content: flex-end; 
}

.btn-group.logout-group .logout-btn {
  margin-left: auto; 
}
.close-btn {
  position: absolute;
  bottom: 850px;
  /* top: 10px; */
  right: 30px;
  background-color: #dc3545;
  color: white; 
  border-radius: 25px;
  padding: 10px;
}


/* Responsive styles */
@media (max-width: 768px) {
  .profile-drawer {
    width: 100%;
    right: -100%; 
    border-radius: 0;
  }
  
  .profile-container {
    padding: 10px;
    margin-top: 0;
  }
  
  .profile-heading {
    font-size: 20px;
  }
  
  .user-info .info-item {
    margin-left: 0; 
  }
  
  .input-group label {
    flex: 1; 
    text-align: left;
  }
  
  .profile-input {
    width: 100%; 
  }
  
  .btn-group {
    flex-direction: column; 
    align-items: center; 
  }
  
  .btn {
    width: 100%; 
    text-align: center; 
  }
  
  .close-btn {
    top: 10px;
    right: 10px;
    height: 30px;
    width: 30px;
  }
}